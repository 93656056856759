import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Head from '../components/Head';
import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';
//import { BLOCKS, MARKS } from '@contentful/rich-text-types';
//import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import Title from '../components/Title/title';
import ButtonLink from '../components/Button/secondaryButton';
import RichText from '../components/shared/RichText';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 68rem;
  font-size: 2.1rem;
  width: 80%;
  margin: 0 auto 3rem;
//margin-bottom: 3rem;
`;

const BlogDate = styled.div`
  font-size: 1.8rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.grey};
`;

const BlogScripture = styled.div`
  text-align: left;
  font-family: Roboto slab;
margin-bottom:4rem;
  // TODO: Provide a better selector or className for the scripture numbers
  p:nth-child(2) {
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.8rem;
    text-align: right;
    &::before {
      content: '\\2014\\0020';
    }
  }

  blockquote p {
    font-style: italic;
    font-weight: 400;
    &::before {
      content: '\\201c';
      text-align: justify;
      text-justify: inter-word;
      font-size: 50px;
      display: block;
      margin-bottom: -3rem;
      margin-top: -2rem;
    }
  }
`;

// TODO: Style blog content
const BlogContent = styled.div`
  font-family: Roboto slab;
    margin-top: 5rem;
  .bold {
    font-size: 2.3rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
  }
`;

//const BlogImage = styled.div`
//  height: 40vh;
//  margin: 2rem 0;
//  background-size: cover;
//  background-position: center;
//`;

//const Paragraph = styled.p`
//  margin-top: 1rem;
//`;

//export const query = graphql`
//  query($slug: String!) {
//    contentfulBlogPost(slug: { eq: $slug }) {
//      title
//      publishedDate(formatString: "MMMM Do, YYYY")
//      titleScripture {
//        json
//      }
//      body {
//        json
//      }
//    }
//  }
//`;
export const query = graphql`
  query BlogPostTempletQuery($slug: String)  {
     contentfulBlogPost(slug: {eq: $slug }) {
    slug
    body {
      raw
    }
    publishedDate(formatString: "MMM DD, YYYY")
    title
    titleScripture {
      raw
    }
        titleImage {
          gatsbyImageData(width: 700, height: 400, layout: CONSTRAINED)
        }
    }
  }
`
const Blog = (props) => {    
  //const Bold = ({ children }) => <p className="bold">{children}</p>;
  //const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
  //const options = {
  //  renderNode: {
  //    [BLOCKS.EMBEDDED_ASSET]: node => {
  //      // const alt = node.data.target.fields.title['en-US'];
  //      const url = node.data.target.fields.file['en-US'].url;
  //      return <BlogImage style={{ backgroundImage: `url(${url})` }} />;
  //    },
  //    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  //  },
  //  renderMark: {
  //    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  //  },
  //};
    
  return (
      <ThemeProvider theme={theme}>
          <Layout>
              <Head title={props?.pageResources?.json.data.contentfulBlogPost.title} />
              <BlogContainer>
                  <Title title={props?.pageResources?.json.data.contentfulBlogPost.title} />

                  <BlogDate>{props?.pageResources?.json.data.contentfulBlogPost.publishedDate}</BlogDate>
                  <BlogScripture>
                      {props?.pageResources?.json.data.contentfulBlogPost.body !== null &&
                          props?.pageResources?.json.data.contentfulBlogPost.body !== undefined ?
                          <RichText raw={props?.pageResources?.json.data.contentfulBlogPost.titleScripture.raw} /> :
                          null}
                  </BlogScripture>
                  { props?.pageResources?.json.data.contentfulBlogPost.titleImage !== null &&
                      props?.pageResources?.json.data.contentfulBlogPost.titleImage !== undefined ?
                      <GatsbyImage image={getImage(props?.pageResources?.json.data.contentfulBlogPost.titleImage.gatsbyImageData)} alt={props?.pageResources?.json.data.contentfulBlogPost.title} /> :
                      null }
                  <BlogContent>
                      {props?.pageResources?.json.data.contentfulBlogPost.body !== null &&
                          props?.pageResources?.json.data.contentfulBlogPost.body !== undefined ?
                          <RichText raw={props?.pageResources?.json.data.contentfulBlogPost.body.raw} /> :
                          null}
                  </BlogContent>
                  <ButtonLink path="/blog" text="ALL POSTS" />
              </BlogContainer>
          </Layout>
    </ThemeProvider>
  );
};

export default Blog;
