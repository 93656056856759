import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ButtonLink = styled(Link)`
  color: ${({ theme }) => theme.colors.carissma2};
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.carissma2};
  padding: 4px 6px;
  /* margin: 1.6rem 0; */
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s;

  /* TODO: Box shadows & transistions not working */

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.carissma2};
    color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 1rem 2rem rgba(#000, 0.3);
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba(#000, 0.15);
    transform: translateY(0);
  }
`;

const SecondaryButtonLink = ({ text, path }) => {
  return <ButtonLink to={`${path}`}>{text}</ButtonLink>;
};

export default SecondaryButtonLink;
